<template>
  <mcr-loading-indicator :loading="true" message="Loading through your institution..."></mcr-loading-indicator>
</template>

<script>
export default {
  name: 'LoadingIpLogin',
};
</script>

<style scoped></style>
