import allRecordsSearchModule from '@common/store/all-records-search';
import basicGetters from '@common/store/basic/getters';
import basicMutations from '@common/store/basic/mutations';
import basicState from '@common/store/basic/state';
import cemeteryModule from '@common/store/cemetery';
import clanModule from '@common/store/clan';
import clanPedigreeModule from '@common/store/clan-pedigree';
import clanPedigreesSearchModule from '@common/store/clan-pedigrees-search';
import dictionaryModule from '@common/store/dictionary';
import galleryModule from '@common/store/gallery';
import glossaryModule from '@common/store/glossary';
import helperModule from '@common/store/helper';
import newspapersModule from '@common/store/newspapers';
import placeModule from '@common/store/place';
import sourceModule from '@common/store/source';
import sourceMentionModule from '@common/store/source-mention';
import sourceSearchModule from '@common/store/source-text-search';
import surnamesSearchModule from '@common/store/surnames-search';
import switchesModule from '@common/store/switches';
import wikiModule from '@common/store/wiki';
import Vue from 'vue';
import Vuex from 'vuex';
import VuexPersist from 'vuex-persist';
import createMutationsSharer from 'vuex-shared-mutations';

import commonGetters from './getters';
import supportModule from './modules/support';
import userModule from './modules/user';
import commonMutations from './mutations';
import commonState from './state';

Vue.use(Vuex);

/* eslint-disable no-unused-vars */
const vuexLocalStorage = new VuexPersist({
  key: 'store',
  storage: window.localStorage,
  reducer: state => {
    return {
      userState: state.userState,
      helperState: {
        reviewedItems: state.helperState.reviewedItems,
        dismissedNotifications: state.helperState.dismissedNotifications,
      },
    };
  },
});

const userMutations = Object.keys(userModule.mutations);
const sharedMutationsPlugin = createMutationsSharer({predicate: userMutations});

export default new Vuex.Store({
  actions: {},
  getters: {...basicGetters, ...commonGetters},
  mutations: {...basicMutations, ...commonMutations},
  state: {...basicState, ...commonState},
  modules: {
    clanPedigreeState: clanPedigreeModule,
    cemeteryState: cemeteryModule,
    helperState: helperModule,
    switchesState: switchesModule,
    userState: userModule,
    supportState: supportModule,
    glossaryState: glossaryModule,
    galleryState: galleryModule,
    clanState: clanModule,
    placeState: placeModule,
    newspapersState: newspapersModule,
    dictionaryState: dictionaryModule,
    sourceState: sourceModule,
    sourceMentionState: sourceMentionModule,
    sourceSearchState: sourceSearchModule,
    allRecordsSearchState: allRecordsSearchModule,
    clanPedigreesSearchState: clanPedigreesSearchModule,
    surnamesSearchState: surnamesSearchModule,
    wikiState: wikiModule,
  },
  plugins: [vuexLocalStorage.plugin, sharedMutationsPlugin],
});
