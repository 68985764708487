import Router from 'vue-router';

import routes from './routes';

export default new Router({
  mode: 'history',
  scrollBehavior(to, from, savedPosition) {
    if (!to.meta.isFWSReport && to.hash) {
      return {selector: to.hash, offset: {x: 0, y: 100}};
    }
    if (to.name === from.name) {
      return false;
    }
    return {x: 0, y: 0};
  },
  routes: routes.default,
});
