<template>
  <mcr-button-router-link v-if="isButton" :to="to" class="mcr-button-router-link-to-pr" :label="label" @click="click">
  </mcr-button-router-link>
  <router-link v-else :to="to">
    <span
      class="mcr-button-router-link-to-pr"
      @click="click"
      @click.ctrl="click"
      @click.meta="click"
      @click.middle="click"
    >
      <slot>{{ label }}</slot>
    </span>
  </router-link>
</template>

<script>
import McrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import AnalyticsFacebookPixelHandler from '@common/utils/analytics/analytics.facebook.pixel';
import AnalyticsHandler from '@common/utils/analytics/analytics.handler';
import {getRoutePageName} from '@common/utils/analytics/utils.analytics';

export default {
  components: {McrButtonRouterLink},
  props: {
    label: String,
    toStep: String, // deprecated
    isButton: {type: Boolean, default: true},
  },
  computed: {
    to() {
      return {name: 'get-in-touch'};
    },
  },
  methods: {
    click() {
      this.$emit('click');
      AnalyticsHandler.trackGoToProspectRequestClickEvent(this.label);
      AnalyticsFacebookPixelHandler.trackContactEvent();
      AnalyticsAmplitudeHandler.trackClickContactButton(this.label, false, getRoutePageName(this.$route));
      this.saveEntryPoint();
    },
    saveEntryPoint() {
      this.$store.commit('saveProspectRequestEntryPointUrl', this.$route.fullPath);
    },
  },
};
</script>

<style lang="scss" scoped></style>
