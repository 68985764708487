import network from '@common/network/network';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';

export default {
  fetchFeatureSwitchesAction(context) {
    let switches = {};
    return new Promise((resolve, reject) => {
      network.switches
        .get()
        .then(response => {
          switches = {
            ...response.flags,
            ...response.switches,
            ...response.samples,
          };
          context.commit('setFeatureSwitchesState', switches);

          const enabledSwitchesNames = Object.keys(switches).filter(k => switches[k]);
          AnalyticsAmplitudeHandler.setUserFeaturesProperty(enabledSwitchesNames);
        })
        .catch(() => {})
        .finally(() => {
          context.commit('setFeatureSwitchesLoadedState', true);
          resolve(switches);
        });
    });
  },
};
