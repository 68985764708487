import {NetworkAPIVersion, NetworkParamType} from '@common/network/network.utils';

export default {
  ocrSelection: {
    url: '/api/ocr/ocr-image-area',
    method: 'post',
    apiVersion: NetworkAPIVersion.noVersion,
    params: {
      url: [NetworkParamType.string],
      lang: [NetworkParamType.string],
      x1: [NetworkParamType.number],
      x2: [NetworkParamType.number],
      y1: [NetworkParamType.number],
      y2: [NetworkParamType.number],
    },
  },
};
