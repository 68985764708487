import network from '@common/network/network';

export default {
  userIpAddressLoginAction(context) {
    context.commit('setIpLoginLoadingState', true);
    return new Promise((resolve, reject) => {
      network.member
        .ipAddressLogin()
        .then(response => {
          // even if login fails, the endpoint returns 200, but has error details in `_reason`
          if (response && (!response._reason || response._reason.length === 0)) {
            context.commit('setUserUsernameState', response.username);
            context.commit('setUserKeyState', response.key);
            context.commit('setClanPedigreeDetailsState', null);
            context.commit('setPersonMentionsState', null);
            context.commit('setLoginCalledDatetimeState');
            resolve(response);
          } else {
            context.commit('setLoginCalledDatetimeState');
            reject();
          }
        })
        .catch(reject)
        .finally(() => {
          context.commit('setIpLoginLoadingState', false);
        });
    });
  },
  isUserIpValidAction(context) {
    return new Promise((resolve, reject) => {
      network.member
        .ipAddressLogin()
        .then(response => {
          // even if login fails, the endpoint returns 200, but has error details in `_reason`
          if (response && (!response._reason || response._reason.length === 0)) {
            resolve(response);
          } else {
            reject();
          }
        })
        .catch(reject);
    });
  },
  userLoginAction(context, {email, password}) {
    return new Promise((resolve, reject) => {
      context.commit('clearUserState');
      context.commit(
        'setNextRefreshTimeState',
        new Date().getTime() + parseInt(process.env.VUE_APP_REFRESH_TOKEN_TIMEOUT)
      );
      network.member.login({email, password}).then(response => {
        // even if login fails, the endpoint returns 200, but has error details in `_reason`
        if (response && (!response._reason || response._reason.length === 0)) {
          if (response.key && response.is_shared) {
            context.commit('setUserUsernameState', response.username);
            context.commit('setUserKeyState', response.key);
            context.commit('setClanPedigreeDetailsState', null);
            context.commit('setPersonMentionsState', null);
          }
          context.commit('setLoginCalledDatetimeState');
          resolve(response);
        } else {
          context.commit('setLoginCalledDatetimeState');
          resolve(response);
        }
      });
    });
  },
  refreshMyInformationAction(context) {
    return new Promise((resolve, reject) => {
      network.member
        .refresh()
        .then(response => {
          context.commit('setResponseUserState', response);
          resolve(response);
        })
        .catch(error => {
          reject(error);
        });
    });
  },
  userLogoutAction(context) {
    return new Promise((resolve, reject) => {
      context.commit('clearUserState');
      resolve();
    });
  },
};
