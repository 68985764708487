import {publishStatuses} from '@common/utils/consts.source';

export default {
  setPersonMentionsState(state, mentions) {
    state.mentions = mentions;
  },
  activatePersonMentionsState(state, sourceId) {
    const mentions = {...state.mentions};
    const source = mentions.source_mentions.find(s => s.id === sourceId);
    source.status = publishStatuses.ACTIVE;
    source.status_display = 'Active';
    state.mentions = mentions;
  },
  setPersonMentionsLoadingState(state, loading) {
    state.mentionsLoading = loading;
  },
  setPersonMentionsImagesState(state, data) {
    state.mentionsImages = data;
  },
  addToPersonMentionsImagesState(state, data) {
    state.mentionsImages = [...state.mentionsImages, ...data];
  },
  setPersonMentionsImagesMetaState(state, data) {
    state.mentionsImagesMeta = data;
  },
  setPersonMentionsImagesLoadingState(state, loading) {
    state.mentionsImagesLoading = loading;
  },
  setMentionsOptionsState(state, options) {
    state.options = options;
  },
  setMentionsOptionsLoadingState(state, loading) {
    state.optionsLoading = loading;
  },
  setMentionPrevRouteState(state, route) {
    state.mentionPrevRoute = route;
  },
};
