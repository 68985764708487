import UserStorage from '@common/utils/user.storage';
import {isCypressEnv} from '@common/utils/utils-e2e';

export const disableTracking = () => {
  return isCypressEnv() || UserStorage.getImpersonatedUserID();
};
export const getRoutePageName = route => {
  return route.meta && route.meta.getRouteName ? route.meta.getRouteName(route) : route.name;
};
export const getRoutePageIdentifier = route => {
  return route.meta && route.meta.getIdentifier ? route.meta.getIdentifier(route).toString() : '';
};
