const USER_FIELDS_MAPPING = {
  username: 'username',
  first_name: 'firstName',
  last_name: 'lastName',
  email: 'email',
  key: 'key',
  is_staff: 'isStaff',
  is_shared: 'isShared',
};

export default {
  setUserUsernameState(state, username) {
    state.username = username;
  },
  setUserFirstNameState(state, firstName) {
    state.firstName = firstName;
  },
  setUserLastNameState(state, lastName) {
    state.lastName = lastName;
  },
  setUserEmailState(state, email) {
    state.email = email;
  },
  setUserKeyState(state, key) {
    state.key = key;
  },
  setResponseUserState(state, responseData) {
    Object.keys(responseData).forEach(key => {
      const stateKey = USER_FIELDS_MAPPING[key];
      if (stateKey) {
        state[stateKey] = responseData[key];
      }
    });
  },
  clearUserState(state) {
    localStorage.removeItem('session_key');
    Object.values(USER_FIELDS_MAPPING).forEach(key => {
      state[key] = null;
    });
  },
  setLoginCalledDatetimeState(state) {
    state.loginCalledDatetime = new Date().getTime();
  },
};
