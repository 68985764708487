export default {
  username: null,
  firstName: null,
  lastName: null,
  email: null,
  key: null,
  isStaff: false,
  isShared: false,
  loginCalledDatetime: 0,
};
