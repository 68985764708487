import network from '@common/network/network';

import {getFamilyTreeClanModalReviewedIdentifier} from './utils';

export default {
  reviewFamilyTreeClanModalAction(context, {familyTreeId, clanSurname}) {
    context.commit('addReviewedItemState', getFamilyTreeClanModalReviewedIdentifier(familyTreeId, clanSurname));
  },
  getNotificationsAction(context) {
    return new Promise((resolve, reject) => {
      network.member.getNotifications().then(response => {
        context.commit('setNotificationsState', response);
        resolve(response);
      });
    });
  },
  startOnboardingWizardAction(context, {treeId, userEmail}) {
    treeId = treeId.toString();
    if (context.getters.onboardingWizardState.hasOwnProperty(treeId)) {
      return;
    }
    const data = {
      userEmail: userEmail,
      requireReview: true,
      showButtonTooltip: null,
      stepAddParents: false,
      stepAddGrandparent: false,
      stepViewGrandparent: false,
      stepBirthGrandparent: false,
    };
    context.commit('startOnboardingWizardState', {treeId, data});
  },
  showWizardButtonTooltipAction(context, {treeId}) {
    const allow =
      context.getters.onboardingWizardState[treeId] &&
      context.getters.onboardingWizardState[treeId].showButtonTooltip === null;
    if (!allow) {
      return;
    }
    setTimeout(() => {
      context.commit('updateOnboardingWizardState', {treeId, data: {showButtonTooltip: true}});

      setTimeout(() => {
        context.commit('updateOnboardingWizardState', {treeId, data: {showButtonTooltip: false}});
      }, 5000);
    }, 300);
  },
  updateOnboardingWizardInfoAction(context, {treeId, drawnPersons, forceReview}) {
    treeId = treeId.toString();
    const wizards = context.getters.onboardingWizardState;
    if (!wizards[treeId]) {
      return;
    }
    const userEmail = context.getters.userEmailState;
    const startPerson = drawnPersons.find(p => p.is_start_person);
    if (wizards[treeId].userEmail === userEmail && startPerson.is_current_user) {
      const parents = startPerson.relatives && startPerson.relatives.parents ? startPerson.relatives.parents : [];
      const parentIds = parents.map(p => p.object_id);
      const grandParents = drawnPersons
        .filter(p => parentIds.includes(p.object_id))
        .map(p => (p.relatives && p.relatives.parents ? p.relatives.parents : []))
        .flat();
      const grandParentsIds = grandParents.map(p => p.object_id);
      const grandParentsData = drawnPersons.filter(p => grandParentsIds.includes(p.object_id));

      const {requireReview, stepAddParents, stepAddGrandparent, stepBirthGrandparent} = wizards[treeId];

      const parentsAdded = parentIds.length >= 2;
      const grandParentAdded = grandParentsIds.length > 0;
      const birthAdded = grandParentsData.some(
        p => (p.birth_date && (p.birth_date.day || p.birth_date.month || p.birth_date.year)) || p.birth_location
      );
      const newRequireReview =
        (!stepAddParents && parentsAdded) ||
        (!stepAddGrandparent && grandParentAdded) ||
        (!stepBirthGrandparent && birthAdded);

      const data = {
        requireReview: requireReview || newRequireReview || forceReview,
        grandParentsIds: grandParentsIds,
        stepAddParents: stepAddParents || parentsAdded,
        stepAddGrandparent: stepAddGrandparent || grandParentAdded,
        stepBirthGrandparent: stepBirthGrandparent || birthAdded,
      };
      context.commit('updateOnboardingWizardState', {treeId, data});
    }
  },
};
