import {getFamilyTreeClanModalReviewedIdentifierRegex} from './utils';

export default {
  fullPathBeforeLoginRegisterState(state) {
    return state.fullPathBeforeLoginRegister || '';
  },
  reviewedItemsState(state) {
    return state.reviewedItems;
  },
  notificationsState(state) {
    return state.notifications;
  },
  dismissedNotificationsState(state) {
    return state.dismissedNotifications;
  },
  reviewedFamilyTreeClanModalSurnamesState(state) {
    const regex = getFamilyTreeClanModalReviewedIdentifierRegex();
    return state.reviewedItems.map(item => item.match(regex)[1]).filter(surname => !!surname);
  },
  clanPedigreeDetailsPreviousRouteState(state) {
    return state.clanPedigreeDetailsPreviousRoute;
  },
  previousRouteState(state) {
    return state.previousRoute;
  },
  onboardingWizardState(state) {
    return state.onboardingWizard;
  },
  onboardingWizardSavedState(state) {
    return state.onboardingWizardSaved;
  },

  showCardQuickEditTooltipState(state) {
    return state.showCardQuickEditTooltip;
  },
  showCardQuickViewTooltipState(state) {
    return state.showCardQuickViewTooltip;
  },
  showCardFullProfileTooltipTooltip(state) {
    return state.showCardFullProfileTooltip;
  },
  nextVerifyNotificationTimeState(state) {
    return state.nextVerifyNotificationTime || new Date().getTime();
  },
};
