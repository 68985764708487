import network from '@common/network/network';
import {responseToBase64String} from '@common/utils/utils';
import {getClearSourcesSearchFacets} from '@common/utils/utils.sources';
import forEach from 'lodash/forEach';
import identity from 'lodash/identity';
import pickBy from 'lodash/pickBy';

export default {
  fetchSourceDetailsAction(context, {id, exclude}) {
    context.commit('setSourceDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getSource({id, exclude})
        .then(response => {
          context.commit('setSourceDetailsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSourceDetailsLoadingState', false);
        });
    });
  },
  fetchCollectionDetailsAction(context, id) {
    context.commit('setCollectionDetailsLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getCollectionDetails({id})
        .then(response => {
          context.commit('setCollectionDetailsState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setCollectionDetailsLoadingState', false);
        });
    });
  },
  searchSourceContentAction(context, {sourceId, query, offset}) {
    if (!query) {
      context.commit('setSourceImagesSearchResultsState', []);
      context.commit('setSourceImagesSearchMetaState', {});
      return Promise.resolve();
    }

    context.commit('setSourceImagesSearchLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .searchImagesContent({source_id: sourceId, q: query, offset})
        .then(response => {
          context.commit('setSourceImagesSearchResultsState', response.objects);
          context.commit('setSourceImagesSearchMetaState', response.meta);

          resolve(response);
        })
        .finally(() => {
          context.commit('setSourceImagesSearchLoadingState', false);
        });
    });
  },

  fetchPreviewForSearchSourceContentAction(context, {sourceId, search}) {
    forEach(context.getters.sourceImagesSearchResultsState, item => {
      context
        .dispatch('fetchSourceImageSearchPreviewAction', {
          sourceId,
          imageNumber: item.priority,
          search,
          isHorizontal: true,
        })
        .then(base64 => {
          context.commit('updateSourceSearchResultsState', {...item, previewSrc: base64});
        });
    });
  },
  fetchSourceImageSearchPreviewAction(context, {sourceId, imageNumber, search, isHorizontal}) {
    const params = {id: sourceId, imageNumber, search};
    if (isHorizontal) {
      params.is_horizontal = true;
    }
    return new Promise((resolve, reject) => {
      network.source.getImageSearchPreview(params).then(response => {
        resolve(responseToBase64String(response));
      });
    });
  },

  fetchSourceImageAction(context, {sourceId, imageNumber, imageId, search}) {
    context.commit('setSourceImageLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getImage({id: sourceId, imageNumber: imageId || imageNumber, search})
        .then(response => {
          context.commit('setSourceImageByIndexState', {image: response, index: response.priority - 1});
          if (!response.url) {
            context.dispatch('fetchSourceImagePreviewAction', {sourceId, imageNumber: response.priority});
          }
          resolve(response);
        })
        .finally(() => {
          context.commit('setSourceImageLoadingState', false);
        });
    });
  },
  fetchSourceImagePreviewAction(context, {sourceId, imageNumber}) {
    context.commit('setSourceImageLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getImagePreview({id: sourceId, imageNumber})
        .then(response => {
          context.commit('setSourceImagePreviewState', {
            imageIndex: imageNumber - 1,
            previewSrc: responseToBase64String(response),
          });
          resolve(response);
        })
        .finally(() => {
          context.commit('setSourceImageLoadingState', false);
        });
    });
  },
  fetchSourceImageOcrAction(context, {sourceId, imageNumber, imageId, ocrType}) {
    context.commit('setSourceImageOcrLoadingState', true);
    return new Promise((resolve, reject) => {
      ocrType = ocrType || '';
      network.source
        .getImageOCR({id: sourceId, imageNumber: imageId || imageNumber, ocrType})
        .then(response => {
          context.commit('setSourceImageOcrByIndexState', {ocr: response, index: response.priority - 1});
          resolve(response);
        })
        .catch(() => {
          if (imageNumber) {
            context.commit('setSourceImageOcrByIndexState', {ocr: null, index: imageNumber - 1});
          }
        })
        .finally(() => {
          context.commit('setSourceImageOcrLoadingState', false);
        });
    });
  },
  bulkRemoveSourceImagesAction(context, {imageIds}) {
    context.commit('setDeleteImagesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .bulkRemoveImages({image_ids: imageIds})
        .then(response => {
          resolve();
        })
        .finally(() => {
          context.commit('setDeleteImagesLoadingState', false);
        });
    });
  },
  searchSourcesAction(context, filters) {
    context.commit('setSearchAllSourcesLoadingState', true);
    const meta = context.getters.searchAllSourcesMetaState;
    if (meta && meta.facets) {
      context.commit('setSearchAllSourcesMetaState', {
        ...meta,
        facets: getClearSourcesSearchFacets(filters, meta.facets),
      });
    }

    const limit = 10;
    const offset = limit * ((filters.page || 1) - 1);
    filters.limit = limit;
    filters.offset = offset;

    const params = pickBy(filters, identity);

    return new Promise((resolve, reject) => {
      network.source
        .searchSources(params)
        .then(response => {
          context.commit('setSearchAllSourcesListState', response.objects);
          context.commit('setSearchAllSourcesMetaState', response.meta);
          resolve();
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSearchAllSourcesLoadingState', false);
        });
    });
  },
  searchSourcesInBackgroundAction(context, filters) {
    const limit = 10;
    const offset = limit * (filters.page - 1);
    filters.limit = limit;
    filters.offset = offset;

    const params = pickBy(filters, identity);

    return new Promise((resolve, reject) => {
      network.source.searchSources(params).then(resolve).catch(reject);
    });
  },

  fetchSearchSourcesOptionsAction(context) {
    context.commit('setSearchAllSourcesOptionsLoadingState', true);

    return new Promise((resolve, reject) => {
      network.source
        .getSourcesOptions()
        .then(response => {
          context.commit('setSearchAllSourcesOptionsState', response.meta);
          resolve(response);
        })
        .catch(err => {
          reject(err);
        })
        .finally(() => {
          context.commit('setSearchAllSourcesOptionsLoadingState', false);
        });
    });
  },

  runOcrForImageArea(context, {url, lang, x1, x2, y1, y2}) {
    return network.ocr.ocrSelection({
      url,
      lang,
      x1,
      x2,
      y1,
      y2,
    });
  },
  translateTextAction(context, {text}) {
    return network.member.translate({text});
  },
  fetchSourcesAutocompleteAction(context, {source_types, q}) {
    context.commit('setSourcesAutocompleteLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .searchSourcesAutocomplete({source_types, q})
        .then(response => {
          context.commit('setSourcesAutocompleteState', response.objects);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSourcesAutocompleteLoadingState', false);
        });
    });
  },

  fetchSearchCategoryAction(context, {id}) {
    context.commit('setSearchCategoryLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getSearchCategory({id})
        .then(response => {
          context.commit('setSearchCategoryState', response);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSearchCategoryLoadingState', false);
        });
    });
  },
  fetchSearchCategoriesListAction(context) {
    context.commit('setSearchCategoriesLoadingState', true);
    return new Promise((resolve, reject) => {
      network.source
        .getSearchCategoriesList()
        .then(response => {
          context.commit('setSearchCategoriesListState', response.objects);
          resolve(response);
        })
        .catch(reject)
        .finally(() => {
          context.commit('setSearchCategoriesLoadingState', false);
        });
    });
  },
};
