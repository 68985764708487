export default {
  userUsernameState(state) {
    return state.username;
  },
  userFirstNameState(state) {
    return state.firstName;
  },
  userLastNameState(state) {
    return state.lastName;
  },
  userFullNameState(state) {
    return `${state.firstName} ${state.lastName}`;
  },
  userEmailState(state) {
    return state.email;
  },
  userKeyState(state) {
    return state.key;
  },
  userIsLoggedInState(state) {
    return Boolean(state.key);
  },
  userIsSharedState(state) {
    return Boolean(state.isShared);
  },
  userIsStaffState(state) {
    return state.isStaff;
  },
  loginCalledDatetimeState(state) {
    return state.loginCalledDatetime;
  },
};
