<template>
  <!-- eslint-disable vue/valid-v-for -->
  <div
    class="main_menu_item"
    :class="{'sub-menu-visible': showSubMenu, 'right-side': separated || isLoginItem}"
    @mouseover="desktopSetIsSubmenuVisible(true)"
    @mouseleave="desktopSetIsSubmenuVisible(false)"
    @click="clickOnMenu"
    v-click-outside="clickOutsideMenu"
  >
    <router-link :to="route" v-if="isRouteIcon" class="icon-menu" v-tooltip="tooltip">
      <component
        :is="icon"
        :title="iconAltText"
        @click="onMenuItemClick"
        @click.ctrl="onMenuItemClick"
        @click.meta="onMenuItemClick"
        @click.middle="onMenuItemClick"
      ></component>
      <div v-if="needReview" class="need-review-indicator"></div>
    </router-link>
    <router-link v-else-if="isLoginItem" class="main_menu_link login_item" rel="nofollow" :to="route"
      >Log in</router-link
    >
    <mcr-button-router-link
      v-else-if="isButtonRouterLinkItem"
      class="button_link_item"
      :to="route"
      @click="trackClick(displayText)"
      >{{ displayText }}</mcr-button-router-link
    >
    <router-link class="main_menu_link" :class="{has_submenu: subMenu}" v-else-if="route" :to="route">
      <span
        @click="onMenuItemClick"
        @click.ctrl="onMenuItemClick"
        @click.meta="onMenuItemClick"
        @click.middle="onMenuItemClick"
      >
        {{ displayText }}
      </span>
    </router-link>
    <a v-else-if="isUserItem" class="user-menu has_submenu cursor-default"
      ><account-icon title="Account Settings"></account-icon
    ></a>
    <a v-else class="main_menu_link" :class="{has_submenu: subMenu}">{{ displayText }}</a>
    <div v-show="showSubMenu" class="main_menu_dropdown">
      <template v-for="item in subMenu">
        <hr v-if="item.isSeparator" />
        <mcr-button-router-link
          v-else-if="item.isButtonRouterLinkItem"
          class="button_link_item"
          :to="item.route"
          @click="trackClick(item.text)"
          >{{ item.text }}</mcr-button-router-link
        >
        <router-link
          v-else-if="item.route"
          :to="item.route"
          @click.native.stop="
            trackClick(item.text);
            setIsSubmenuVisible(false);
          "
          class="sub-menu-link"
          >{{ item.text }}</router-link
        >
        <a
          class="sub-menu-link"
          :class="{'has-icon': !!item.icon}"
          v-else-if="item.clickHandler"
          @click="
            trackClick(item.text);
            item.clickHandler();
            setIsSubmenuVisible(false);
          "
        >
          <span>{{ item.text }}</span>
          <component :is="item.icon" :size="25" v-if="item.icon"></component>
        </a>
        <mcr-button-router-link-to-pr
          v-else-if="item.isContactUsButton"
          label="Contact Us"
        ></mcr-button-router-link-to-pr>
        <div class="heading-6" :class="item.class" v-else>
          <component :is="item.icon" :size="20" v-if="item.icon"></component>
          <span>{{ item.text }}</span>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import mcrButtonRouterLink from '@common/elements/buttons/mcrButtonRouterLink';
import McrButtonRouterLinkToPr from '@common/elements/buttons/mcrButtonRouterLinkToPr';
import AnalyticsAmplitudeHandler from '@common/utils/analytics/analytics.amplitude';
import {getRoutePageIdentifier, getRoutePageName} from '@common/utils/analytics/utils.analytics';
import AccountIcon from 'vue-material-design-icons/Account';
import BankIcon from 'vue-material-design-icons/Bank';
import HelpIcon from 'vue-material-design-icons/HelpCircleOutline';
import LightbulbIcon from 'vue-material-design-icons/Lightbulb';

export default {
  components: {McrButtonRouterLinkToPr, mcrButtonRouterLink, AccountIcon, HelpIcon, LightbulbIcon, BankIcon},
  data() {
    return {
      isSubmenuVisible: false,
    };
  },
  props: {
    isMobileView: Boolean,
    text: {type: String},
    mobileText: {type: String, required: false},
    mobileSeText: {type: String, required: false},
    subMenu: {type: Array, required: false},
    isUserItem: {type: Boolean, default: false},
    isLoginItem: {type: Boolean, default: false},
    isButtonRouterLinkItem: {type: Boolean, default: false},
    needReview: {type: Boolean, default: false},
    route: {type: Object, required: false},
    icon: {type: String, required: false},
    iconAltText: {type: String, required: false},
    tooltip: {type: Object, required: false},
    separated: {type: Boolean, default: false},
    clickHandler: {type: Function, required: false},
  },
  computed: {
    displayText() {
      if (this.mobileSeText && this.isMobileSe) {
        return this.mobileSeText;
      }
      return this.mobileText && this.isMobileView ? this.mobileText : this.text;
    },
    showSubMenu() {
      return this.subMenu && this.isSubmenuVisible;
    },
    isMobileSe() {
      return this.$store.getters.windowWidthState <= this.$breakpoints.mobileSe;
    },
    isRouteIcon() {
      return this.route && this.icon;
    },
  },
  methods: {
    setIsSubmenuVisible(value) {
      this.isSubmenuVisible = value;
    },
    desktopSetIsSubmenuVisible(value) {
      return this.isMobileView ? null : this.setIsSubmenuVisible(value);
    },
    mobileSetIsSubmenuVisible(value) {
      return this.isMobileView ? this.setIsSubmenuVisible(value) : null;
    },
    clickOnMenu() {
      this.mobileSetIsSubmenuVisible(!this.isSubmenuVisible);
    },
    clickOutsideMenu(event) {
      this.mobileSetIsSubmenuVisible(false);
    },
    onMenuItemClick() {
      this.trackClick(this.displayText);
      if (this.clickHandler) {
        this.clickHandler();
      }
    },
    trackClick(label) {
      AnalyticsAmplitudeHandler.trackClickMenuItem(
        label,
        getRoutePageName(this.$route),
        getRoutePageIdentifier(this.$route)
      );
    },
  },
  name: 'main.menu.item',
};
</script>

<style lang="scss" scoped>
.main_menu_item::v-deep a:focus {
  text-decoration: none;
}

.main_menu_item {
  cursor: default;

  .main_menu_link {
    color: $off-white;
    display: flex;
    align-items: center;
    span {
      display: block;
    }
    &.has_submenu {
      cursor: default;
      &::after {
        content: '\25BC';
        font-size: 0.5em;
        padding-left: 4px;
        position: relative;
      }
    }
    &:hover,
    &:focus {
      text-decoration: none;
    }
    &:not(.has_submenu) {
      cursor: pointer;
      &:hover {
        text-decoration: underline;
      }
    }

    &.login_item {
      white-space: nowrap;
      color: $main-menu-alt-color;
    }
  }
}

.icon-menu,
.user-menu {
  display: flex;
  position: relative;
  color: $off-white;

  &::v-deep .material-design-icon {
    display: inline-flex;
  }

  &.cursor-default {
    cursor: default;
  }

  .need-review-indicator {
    position: absolute;
    top: 22px;
    right: 6px;
    border-radius: 50%;
    background: $primary-400;
    height: 6px;
    width: 6px;
  }
}

.main_menu_item {
  position: relative;

  .main_menu_dropdown {
    position: absolute;
    overflow-y: auto;
    background-color: $background-alternate-color;
    box-shadow: 2px 2px 5px rgba(0, 0, 0, 0.3);
    border-radius: 4px;
    min-width: 160px;
    z-index: 5;

    .heading-6 {
      color: #757574;
      background-image: linear-gradient(
        180deg,
        rgba($menu-category-background, 0.2),
        rgba($menu-category-background, 0.5)
      );
      font-size: 0.8em;
      font-weight: bold;
      text-transform: uppercase;
      padding: 25px 20px 7px 20px;
      margin-top: 0;
      text-shadow: none;
      display: block;
      border-bottom: 1px solid #ddd;

      &:first-child {
        margin-top: 0;
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
    }

    .button_link_item {
      margin: 16px;
    }

    .sub-menu-link {
      display: block;
      padding: 16px 20px;
      color: $mcr-dark-dark-grey;
      border-bottom: 1px solid #ddd;

      &:hover,
      &.has-icon:hover .material-design-icon {
        background-color: rgba(white, 0.4);
        text-decoration: none;
        color: $link-color;
      }

      &:first-child {
        border-top-left-radius: 4px;
        border-top-right-radius: 4px;
      }
      &:last-child {
        border-bottom-left-radius: 4px;
        border-bottom-right-radius: 4px;
        border-bottom: 0;
      }

      &.has-icon {
        .material-design-icon {
          float: right;
          color: $secondary-text-color;
        }

        @media only screen and (max-width: $main-menu-breakpoint) {
          display: flex;
          .material-design-icon {
            float: none;
          }
        }
      }
    }

    hr {
      border-top: 1px solid #ddd;
      border-bottom: 0;
    }

    .mcr-button-router-link-to-pr {
      display: none;
      @media only screen and (max-width: $main-menu-contact-button-breakpoint) {
        display: flex;
        margin: 20px 20px 0;
      }
    }
  }
}

/* Desktop Styles */
@media only screen and (min-width: calc(#{$main-menu-breakpoint} + 1px)) {
  .main_menu_item {
    .main_menu_link {
      font-size: 18px;
      font-weight: normal;
      text-shadow: #333 0 0 3px;
      white-space: nowrap;
    }

    .main_menu_dropdown {
      position: absolute;
      top: calc(#{$main-menu-height} - 5px);
      max-height: calc(100vh - #{$main-menu-height} - 5px);
      max-width: 240px;
      width: max-content;
      right: 0px;
      transition: all 0.1s ease;

      @media only screen and (max-height: 500px) {
        overflow-y: auto;
      }
    }

    &.sub-menu-visible::after {
      box-shadow: -3px -2px 2px 0 rgba(0, 0, 0, 0.05);
      content: '\00a0';
      display: block;
      height: 15px;
      right: 13px;
      position: absolute;
      bottom: 0;
      transform: rotate(45deg);
      width: 15px;
      background-color: $background-alternate-color;
      z-index: 4;
    }
  }
}

/* Mobile Styles */
@media only screen and (max-width: $main-menu-breakpoint) {
  .main_menu_item {
    > .main_menu_link {
      font-weight: normal;
      text-shadow: #333 0 0 3px;
      font-size: 17px;
      &.has_submenu {
        cursor: pointer;
      }
    }

    > .main_menu_dropdown {
      border-radius: 0;
      position: fixed;
      top: $main-menu-height-mobile;
      box-shadow: 0px 0px 15px rgba(0, 0, 0, 0.2);
      left: 0px;
      width: 100%;
      padding: 0;
      transition: all 0.3s ease;
      max-height: calc(
        100vh - #{$main-menu-height-mobile} - #{$mobile-browser-nav-spacing}
      ); /* accounting for the browser bar */
      overflow-y: auto;
      overflow-x: hidden;

      .sub-menu-link {
        padding: 15px 20px;
      }
    }
  }
  .icon-menu .need-review-indicator {
    top: 16px;
    right: 4px;
  }
}

/* Tiny Screen Styles */
@media only screen and (max-width: 500px) {
  .main_menu_item {
    .main_menu_link {
      &.has_submenu {
        &::after {
          content: ' ';
        }
      }
    }
  }
}
</style>
