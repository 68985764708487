export const initGooglePlacesScript = function () {
  return initScript(
    `https://maps.googleapis.com/maps/api/js?v=weekly&key=${process.env.VUE_APP_GOOGLE_API_KEY}&libraries=places`
  );
};

export const initStripeScript = function () {
  return initScript('https://js.stripe.com/v3/');
};

export const initCalendlyScript = function () {
  initStylesheet('https://assets.calendly.com/assets/external/widget.css', 'calendly-style');
  return initScript('https://assets.calendly.com/assets/external/widget.js');
};

export const initHubspot = function () {
  return initScript('//js.hs-scripts.com/20577381.js');
};

export const initHotjar = function () {
  (function (h, o, t, j, a, r) {
    h.hj =
      h.hj ||
      function () {
        (h.hj.q = h.hj.q || []).push(arguments);
      };
    h._hjSettings = {hjid: process.env.VUE_APP_HOTJAR_ID, hjsv: 6};
    a = o.getElementsByTagName('head')[0];
    r = o.createElement('script');
    r.async = 1;
    r.src = t + h._hjSettings.hjid + j + h._hjSettings.hjsv;
    a.appendChild(r);
  })(window, document, 'https://static.hotjar.com/c/hotjar-', '.js?sv=');
};

export const initScript = function (src) {
  /* Inits the script, makes sure script is not inited twice. Returns Promise, will be resolved once script is loaded */
  return new Promise(function (resolve, reject) {
    let script = document.querySelector('script[src="' + src + '"]');
    if (script && script.hasAttribute('data-loaded')) {
      return resolve(script);
    }
    let shouldAppend = false;
    if (!script) {
      script = document.createElement('script');
      script.setAttribute('src', src);
      script.async = true;
      shouldAppend = true;
    }
    script.addEventListener('error', reject);
    script.addEventListener('abort', reject);
    script.addEventListener('load', () => {
      script.setAttribute('data-loaded', 'true');
      resolve(script);
    });
    if (shouldAppend) {
      document.head.appendChild(script);
    }
  });
};

export const initStylesheet = function (href, id) {
  if (!document.getElementById(id)) {
    const head = document.getElementsByTagName('head')[0];
    const link = document.createElement('link');
    link.id = id;
    link.rel = 'stylesheet';
    link.type = 'text/css';
    link.href = href;
    link.media = 'all';
    head.appendChild(link);
  }
};
